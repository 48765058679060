const qaTechUk = [
  {
    question: "Скільки існує принципів тестування?",
    questionId: 1,
    answers: ["5", "6", "7", "8", "9", "Я не знаю"],
    rightAnswer: "7",
  },
  {
    question: "Перевірка -- це ",
    questionId: 2,
    answers: [
      "Фактично, вона відповідає на питання, чи вірно створено та протестовано програмне забезпечення та чи враховані всі вимоги",
      "Під час процесу перевірки, ми переконуємося, чи всі створені функціональні можливості працюють коректи та логічно, правильно",
      "Структура перевірки включає в себе такі компоненти, як первірка валідовваних вимог, технічну документацію та вірність виповнення програмного коду на будь-якому етапі розробки чи тестування програмного забезпечення",
      "Варінти 2 та 3 вірні",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Валідація - це",
    questionId: 3,
    answers: [
      "Відповідає на питання, чи розробляється продукт відповідно очікуванням кліента",
      "Під час процесу валідації, ми переконуємося, чи повністю продукт відповідає поведінці, яку від нього очікуємо та клієнт знаю про наяність цього функціоналу",
      "Валідація, в основному, включає у себе загальну оцінку програмного забезпечення та може грунтуватися виключно на субʼєктивній думці щодо првальної роботи програми або її компонентів",
      "Варіанти 1 та 3 вірні",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Чо з нижче наведеного є МІНУСОМ методології Waterfall?",
    questionId: 4,
    answers: [
      "Висока прозорість розробки та фаз проекту",
      "Стабільність вимог",
      "Полегшення роботи складання плану проекту збору групи для нього ",
      "Усі вимоги повинні бути відомі до початку життєвого циклу проекту ",
      "Чітка послідовність",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer:
      "Усі вимоги повинні бути відомі до початку життєвого циклу проекту ",
  },
  {
    question:
      "Ця методологія розробки програмного забезпечення в основному спрямована на скорочення роботи, що виконується на даний момент. У цій методиці: немає обмежень у часі ні для завдань, ні для спринтів; завдань стає все менше; Немає прямої необхідності встановлювати терміни виконання завдань, але все одно потрібно виміряти їх для загальної презентації. Про яку методологію йде мова?",
    questionId: 5,
    answers: [
      "Waterfall",
      "Scrum",
      "Agile",
      "Kanban",
      "Це визначення підходить до всіх методологій",
      "Немає вірного варіанту",
    ],
    rightAnswer: "Kanban",
  },
  {
    question: "Що означає ХР у тестуванні",
    questionId: 6,
    answers: [
      "eXPerience",
      "eXtreme Programming",
      "eXperience Points",
      "XP Injection",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "eXtreme Programming",
  },
  {
    question: "Основні ідеї Agile",
    questionId: 7,
    answers: [
      "Люди та взаємодія важливіші за процеси та інструменти",
      "Працюючий продукт важливіший за документацію",
      "Співпраця із замовником важливіша за узгодження умов договору",
      "Бути готовим до змін важливіше за дотримання початкового плану ",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question:
      "Про яку методологію йдеться у цих 4 етапах: Початок, Уточнення, Конструювання, Впровадження",
    questionId: 8,
    answers: [
      "RUP",
      "Kanban",
      "Scrum",
      "Agile",
      "Waterfall",
      "Усі варіанти невірні",
    ],
    rightAnswer: "RUP",
  },
  {
    question: "Правильна послідовність елементів Scrum",
    questionId: 9,
    answers: [
      "Планування спринту та створення беклогу спринту - Робота над спринтом. Scrum-зустрічі - Тестування та демонстрація продукту - Ретроспектива. Планування наступного спринту",
      "Створення беклогу продукту - Робота над спринтом. Scrum-зустрічі - Тестування та демонстрація продукту - Ретроспектива. Планування наступного спринту",
      "Створення беклогу продукту - Планування спринту та створення беклогу спринту - Тестування та демонстрація продукту - Ретроспектива. Планування наступного спринту",
      "Створення беклогу продукту - Планування спринту та створення беклогу спринту - Робота над спринтом. Scrum-зустрічі - Ретроспектива. Планування наступного спринту",
      "Створення беклогу продукту - Планування спринту та створення беклогу спринту - Робота над спринтом. Scrum-зустрічі - Тестування та демонстрація продуктів",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти невірні",
  },
  {
    question: "Які навички найчастіше необхідні від проджект менеджера?",
    questionId: 10,
    answers: [
      "Збір та систематизація вимог проекту",
      "План виконання роботи, KPI, майндмепс",
      "Пошук виконавців, фрілансерів, складання портрета бажаного співробітника для HR",
      "Постановка завдань і контроль дедлайнів",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Які завдання Власника продукту?",
    questionId: 11,
    answers: [
      "Постановка завдань і контроль термінів",
      "Контроль за витратами бюджету",
      "Ведення проектної документації (технічні умови, договори, акти, рахунки, кошториси, звіти)",
      "І ще раз - БЮДЖЕТИ і ТЕРМІНИ - ось що найважливіше в його роботі",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти невірні",
  },
  {
    question: "Product Owner контролює",
    questionId: 12,
    answers: [
      "Хто користується продуктом та які функції важливі для користувачив, а які другорядні",
      "Як користувачі вирішують проблеми та які саме проблеми мають",
      "Як залучити більше користувачів; як зберегти наявних та привести їх до оплати",
      "Чому користувачі відмовляються від продукту (це може бути незручний інтерфейс, технічні помилки, відсутність необхідних функцій або вартість)",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Що таке динамічне тестування?",
    questionId: 13,
    answers: [
      "Це тестування з виконанням лише коду з різними вхідними значеннями та підтвердженням результатів",
      "Це тестування з виконанням лише програми з різними вхідними значеннями та підтвердженням результатів",
      "Це тестування з виконанням коду або програми з різними вхідними значеннями та підтвердженням результатів",
      "Це тестування шляхом виконання коду",
      "Це тестування з різними вводами та перевірка результатів",
      "Я не знаю",
    ],
    rightAnswer:
      "Це тестування з виконанням коду або програми з різними вхідними значеннями та підтвердженням результатів",
  },
  {
    question: "Що таке формальне тестування?",
    questionId: 14,
    answers: [
      "Перевірка програмного забезпечення відповідно до плану тестування, процедур тестування та відповідної документації з урахуванням побажань клієнта",
      "Валідація програмного забезпечення відповідно до плану тестування, процедур тестування та відповідної документації з урахуванням побажань клієнта",
      "Перевірка програмного забезпечення, згідно з процедурами тестування та відповідною документацією, без урахування побажань клієнта",
      "Я не знаю",
    ],
    rightAnswer:
      "Перевірка програмного забезпечення відповідно до плану тестування, процедур тестування та відповідної документації з урахуванням побажань клієнта",
  },
  {
    question:
      "Зручність використання інтерфейсу зазвичай визначається за допомогою ряду якісних показників",
    questionId: 15,
    answers: [
      "Можливість навчання, працездатність, помилки, задоволення",
      "Навчання, запам'ятовування, помилки, задоволення",
      "Працездатність, запам'ятовування, помилки, задоволення",
      "Можливість навчання, працездатність, запам'ятовування, помилки",
      "Можливість навчання, працездатність, запам'ятовування, помилки, задоволення",
      "Я не знаю",
    ],
    rightAnswer:
      "Можливість навчання, працездатність, запам'ятовування, помилки, задоволення",
  },
  {
    question: "Що таке дефект Showstopper",
    questionId: 16,
    answers: [
      "Дефект, який змушує вас відкатити останній реліз",
      "Дефект, який дозволяє здійснити негайний реліз без тестування",
      "Дефект, який змушує вас зупинити процесс тестування",
      "Дефект, тобто відсутність дефекту. Але він має бути",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Дефект, який змушує вас зупинити процесс тестування",
  },
  {
    question: "STLC це –",
    questionId: 17,
    answers: [
      "Етап, на якому визначається стратегія тестування",
      "Життєвий цикл тестування програмного забезпечення",
      "Фінальний етап тестування програмного забезпечення",
      "Абревіатура немає нічого спільного з тестуванням",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Життєвий цикл тестування програмного забезпечення",
  },
  {
    question: "Що з перерахованого зайве ",
    questionId: 18,
    answers: [
      "Test plan",
      "Bug report",
      "Backlog",
      "Check list",
      "Cheat list",
      "Набір тест кейсів та інструментів",
    ],
    rightAnswer: "Backlog",
  },
  {
    question: "Test plan відповідає на такі питання",
    questionId: 19,
    answers: [
      "Що потрібно затестити",
      "Що буде протестоване",
      "Як та коли це буде тестуватись",
      "Старт тестування та критерії закінчення",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Що зайве у критеріях запуску тестування?",
    questionId: 20,
    answers: [
      "SRS - software",
      "FRS",
      "Use case",
      "Test plan",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти невірні",
  },
  {
    question: "Що зайве в критеріях проходження тестування?",
    questionId: 21,
    answers: [
      "Testing report",
      "Metrics",
      "Test Analysis Report",
      "Test case",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Test case",
  },
  {
    question:
      "Це комплексне тестування користувачами одночасного доступу до програми для перевірки впливу на код, модуль або базу даних. В основному виявляє тупики в коді.",
    questionId: 22,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Я не знаю",
    ],
    rightAnswer: "Currency testing",
  },
  {
    question:
      "Це тестування проводиться на веб-сайті для перевірки завантаження, відображення, безпеки, функціональності, інтерфейсу, сумісності та інших проблем, пов’язаних із зручністю використання",
    questionId: 23,
    answers: [
      "Currency testing",
      "Тестування веб-додатку",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Я не знаю",
    ],
    rightAnswer: "Тестування веб-додатку",
  },
  {
    question:
      "Тестування елементів (або побічне тестування) дозволяє перевірити індивідуальну роботу одиниць вихідного коду",
    questionId: 24,
    answers: [
      "Currency testing",
      "Тестування веб-додатку",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Я не знаю",
    ],
    rightAnswer: "Functional testing",
  },
  {
    question:
      "Тестування інтерфейсу перевіряє взаємодію окремих модулів. Найчастіше використовується для тестування інтерфейсу користувача програм із графічним інтерфейсом",
    questionId: 25,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Я не знаю",
    ],
    rightAnswer: "Interface testing",
  },
  {
    question:
      "Це тестування проводиться, коли програмне забезпечення вже готове до випуску, його перевіряють на відповідність вимогам.",
    questionId: 26,
    answers: [
      "Currency testing",
      "Testing the web application",
      "Functional testing",
      "Interface testing",
      "Gamma testing",
      "Я не знаю",
    ],
    rightAnswer: "Gamma testing",
  },
];

export default qaTechUk;
