const qaTheoryUk = [
  {
    question: "За що відповідає 'CI' у тестуванні?",
    questionId: 1,
    answers: [
      "Підключення інтерфейсу",
      "Нічого",
      "Неприривна інтеграція",
      "Сантилітр",
    ],
    rightAnswer: "Неприривна інтеграція",
  },
  {
    question: "Етапи тестування. Оберіть правильну послідовність",
    questionId: 2,
    answers: [
      "Управління вимогами - Аналіз продукту - Розробка стратегії тестування і планування процедур контролю якості - Створення тестової документації - Тестування прототипу - Основне тестування - Стабілізація - Експлуатація",
      "Аналіз продукту - Робота з вимогами - Розробка стратегії тестування та планування процедур контролю якості - Створення тестової документації - Тестування прототипу - Основне тестування - Стабілізація - Експлуатація",
      "Управління вимогами - Аналіз продукту - Розробка стратегії тестування та планування процедур контролю якості - Тестування прототипу - Створення тестової документації - Основне тестування - Стабілізація - Експлуатація",
      "Управління вимогами - Аналіз продукту - Розробка стратегії тестування та планування процедур контролю якості - Створення тестової документації - Тестування прототипу - Стабілізація - Основне тестування - Експлуатація",
    ],
    rightAnswer:
      "Аналіз продукту - Робота з вимогами - Розробка стратегії тестування та планування процедур контролю якості - Створення тестової документації - Тестування прототипу - Основне тестування - Стабілізація - Експлуатація",
  },
  {
    question: "Які головні плюси автоматизованого тестування?",
    questionId: 3,
    answers: [
      "Швидке виконання",
      "Зменшення витрат на обслугововання програмного забезпечення",
      "Економія часу тестувальника",
      "Можливість виконання у неробочий час",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Які основні типи локаторів у Selenium?",
    questionId: 4,
    answers: [
      "ID, ClassName",
      "Name, TagName",
      "LinkText, PartialLinkText",
      "Xpath, CSS Selector, DOM",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти вірні",
  },
  {
    question: "Оберіть вірний приклад  Absolute XPath",
    questionId: 5,
    answers: [
      "html/body/div/[3]/div/div/[1]/div/div/div[1]/div/input",
      "html/body/div(3)/div/div(1)/div/div/div)1)/div/input",
      "html\bodydiv[3]divdiv[1]divdivdiv[1]divinput/",
      "html//body/div[3]/div/div[1]/div/div/div[1]/div/input",
      "html/body/div[3]/div/div[1]/div/div/div[1]/div/input",
    ],
    rightAnswer: "html/body/div[3]/div/div[1]/div/div/div[1]/div/input",
  },
  {
    question:
      "Найчастіше перегляд елементів у браузері Google Chrome на Windows запускається кнопкою",
    questionId: 6,
    answers: ["F5", "F7", "F2", "F9", "F12"],
    rightAnswer: "F12",
  },
  {
    question:
      "Найчастіше перегляд елементів у браузері Google Chrome на Mac запускається комбінацією кнопок",
    questionId: 7,
    answers: ["Cmd+Opt+O", "Cmd+Opt+R", "Cmd+Opt+J", "Cmd+Opt+N", "Cmd+Opt+W"],
    rightAnswer: "Cmd+Opt+J",
  },
  {
    question: "navigate().back(). Ця навігаційна команда у Selenium:",
    questionId: 8,
    answers: [
      "Повертає користувача на попередню сторінку з історії браузера",
      "Дозволяє користувачеві перейти до наступної сторінки відповідно до історії браузера",
      "Використовується для оновлення сторінки браузера",
      "Дозволяє користувачеві відкривати нове вікно у браузері та переходити за вказаною URL-адресою",
      "Немає такої команди",
    ],
    rightAnswer:
      "Повертає користувача на попередню сторінку з історії браузера",
  },
  {
    question: "navigate().forward(). Ця навігаційна команда у Selenium:",
    questionId: 9,
    answers: [
      "Повертає користувача на попередню сторінку з історії браузера",
      "Дозволяє користувачеві перейти до наступної сторінки відповідно до історії браузера",
      "Використовується для оновлення сторінки браузера",
      "Дозволяє користувачеві відкривати нове вікно у браузері та переходити за вказаною URL-адресою",
      "Немає такої команди",
    ],
    rightAnswer:
      "Дозволяє користувачеві перейти до наступної сторінки відповідно до історії браузера",
  },
  {
    question: "navigate().refresh(). Ця навігаційна команда у Selenium:",
    questionId: 10,
    answers: [
      "Повертає користувача на попередню сторінку з історії браузера",
      "Дозволяє користувачеві перейти до наступної сторінки відповідно до історії браузера",
      "Використовується для оновлення сторінки браузера",
      "Дозволяє користувачеві відкривати нове вікно у браузері та переходити за вказаною URL-адресою",
      "Немає такої команди",
    ],
    rightAnswer: "Використовується для оновлення сторінки браузера",
  },
  {
    question: "navigate().to(). Ця навігаційна команда у Selenium:",
    questionId: 11,
    answers: [
      "Повертає користувача на попередню сторінку з історії браузера",
      "Дозволяє користувачеві перейти до наступної сторінки відповідно до історії браузера",
      "Використовується для оновлення сторінки браузера",
      "Дозволяє користувачеві відкривати нове вікно у браузері та переходити за вказаною URL-адресою",
      "Немає такої команди",
    ],
    rightAnswer:
      "Дозволяє користувачеві відкривати нове вікно у браузері та переходити за вказаною URL-адресою",
  },
  {
    question: "Що з наведеного є атрибутом звіту про помилки (Bag Report)",
    questionId: 12,
    answers: [
      "Summary",
      "Attachments",
      "Status",
      "Severity",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Continuous Integration",
  },
  {
    question:
      "Тестування, яке стосується безпеки програми та має на меті запобігання проблем, які можуть вплинути на цілісність на стабільність програми:",
    questionId: 13,
    answers: [
      "Penetration testing",
      "Fuzzy testing",
      "Ramp testing",
      "Vulnerability testing",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Vulnerability testing",
  },
  {
    question:
      "_____ це існуючий дефект, який ще не спричинив збій, тому що умови, які його викликають, ще не досягнуті",
    questionId: 14,
    answers: [
      "Прихований і замаскований дефект",
      "Прихований дефект",
      "Замаскований дефект",
      "Дефект",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Прихований дефект",
  },
  {
    question: "Тестування за межами нормальної продуктивності це",
    questionId: 15,
    answers: [
      "Stress testing",
      "Performance testing",
      "Functional testing",
      "Centiliter",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Stress testing",
  },
  {
    question:
      "Тестувальник виконує тест, щоб оцінити, чи відповідає він запиту користувача на заповнення конкретного поля, використовуючи drop-down список, що містить список значень. Тестувальник виконує",
    questionId: 16,
    answers: [
      "Stress testing",
      "Regression testing",
      "White box testing",
      "Black box testing",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Black box testing",
  },
  {
    question: "Перевірка поля onBlur виконана",
    questionId: 17,
    answers: [
      "Коли ми наводимо на кнопку",
      "Коли ми починаємо вводити дані",
      "Коли ми натискаємо кнопку надіслати",
      "Після ведення даних",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Коли ми натискаємо кнопку надіслати",
  },
  {
    question: "Що не так апелює до тестів Smoke та Sanity?",
    questionId: 18,
    answers: [
      "Якщо обидва виконуються, спочатку проводиться Санітарне тестування, а після Димове",
      "Санітарне тестування також називається Приймальним тестуванням",
      "Димове та Санітарне тестування можуна виконувати автоматично",
      "Димове тестування, що виконується для конктерної конструкції, також називається Тестування збірки",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer:
      "Якщо обидва виконуються, спочатку проводиться Санітарне тестування, а після Димове",
  },
  {
    question: "Принцип тестування 'Парадоксу пестицидів' передбачає",
    questionId: 19,
    answers: [
      "Пошук та усунення дефектів не допомагає, якщо система нестабільна",
      "Тестування проводиться по різному у іншому контексті",
      "Тест кейси треба регулярно переглядати та перевіряти",
      "Модулі кластерізації, які містять найбільше дефектів",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Тест кейси треба регулярно переглядати та перевіряти",
  },
  {
    question:
      "Який тип тестування дозволяє тестувальнику оцінити поведінку програмного забезпечення, коли виникають винятки?",
    questionId: 20,
    answers: [
      "Несправність Injection test",
      "Passive testing",
      "Fuzzy testing",
      "Centiliter",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Несправність Injection test",
  },
  {
    question: "White box testing не стосується",
    questionId: 21,
    answers: [
      "Прийняття рішень",
      "Причин та наслідків охоплення",
      "Декількох умов охоплення",
      "Повідомлення про охоплення",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Причин та наслідків охоплення",
  },
  {
    question: "Що з перерахованого не є тестуванням на основі специфікації?",
    questionId: 22,
    answers: [
      "Перехід стану",
      "Таблиця прийняття рішень",
      "Аналіз гранічних значень",
      "Потік даних",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Потік даних",
  },
  {
    question: "Який порядок приорітетного тестування?",
    questionId: 23,
    answers: [
      "Система >> Прийом >> Інтеграція",
      "Система >> Інтеграція >> Приймальне тестування",
      "Модуль >> Інтеграція >> Тестування системи",
      "Модуль >> Система >> Приймальне тестування",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Модуль >> Інтеграція >> Тестування системи",
  },
  {
    question: "Додаток не дозволяє конфігурацію, очікувану клієнтом, наприклад",
    questionId: 24,
    answers: [
      "High severity and high priority",
      "High severity and low priority",
      "Low Severity and Low Priority",
      "Low Severity and High Priority",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "High severity and low priority",
  },
  {
    question:
      "Який баланс зусиль потрібен для усунення дефекту на етапах вимог, дизайну, кодингу, реалізації?",
    questionId: 25,
    answers: [
      "1: 8: 12: 100",
      "1: 4: 7: 10",
      "1: 10: 100: 1000",
      "1: 6: 10: 1000",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "1: 6: 10: 1000",
  },
  {
    question: "Який відсоток витрат на розробку програмного забезпечення?",
    questionId: 26,
    answers: [
      "10-20",
      "40-50",
      "5-10",
      "70-80",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "40-50",
  },
  {
    question: "Що з перерахованого не є ітеративною моделлю?",
    questionId: 27,
    answers: [
      "Incremental",
      "Spiral Model",
      "V model",
      "RAD",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "V model",
  },
  {
    question: "Використання перевірки та валідації",
    questionId: 28,
    answers: [
      "Тільки зовнішні ресурси",
      "Тільки внутрішні ресури",
      "Зовнішні та внутрішні ресурси, відповідно",
      "Внутрішні та зовнішні ресурси, відповідно",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Внутрішні та зовнішні ресурси, відповідно",
  },
  {
    question:
      "Відкривши посилання на сайт, користувач отримує повідомлення 'Цей розділ нашого сайту обновляється'. Що це за помилка?",
    questionId: 29,
    answers: [
      "Помилка URL-адреси",
      "Функціональна помилка",
      "Структурна помилка",
      "Структурна посилка",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Усі варіанти невірні",
  },
  {
    question:
      "Чи є методика тестування, що включає тестування з різними діапазонами дійсних і недійсних введених даних, функціональністю конкретного модуля або компонента?",
    questionId: 30,
    answers: [
      "Baseline testing",
      "Gorilla Testing",
      "Monkey testing",
      "Agile testing",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Gorilla Testing",
  },
  {
    question: "Фокусне тестування підпадає під __________",
    questionId: 31,
    answers: [
      "Usability testing",
      "Component testing",
      "Acceptance testing",
      "Performance testing",
      "Усі варіанти вірні",
      "Усі варіанти невірні",
    ],
    rightAnswer: "Usability testing",
  },
];

export default qaTheoryUk;
